import React, { useState, useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {MyRouter} from "./pages/router/MyRouter";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import "./App.css";

export const App = () => {


  const theme = createTheme({
    palette: {
      primary: {
        main: '#6AC2FC',
        light: '#28347966',
      },
      secondary: {
        main: '#26018C',
      }
    },
    typography: {
      fontFamily: ['am_barlow'].join(','),
      subtitle1: {
        fontSize: 28
      },
      h2: {
        fontSize: '4vw',  
        fontFamily: 'am_amatic',
        textAlign: 'center',
        color: '#283479' 
      },
      h3: {
        fontSize: '1.5rem',  
        '@media (min-width:600px)': {
          fontSize: '2.5rem',
        },  
        fontFamily: 'roboto_light',
        textAlign: 'left',
        color: '#26018C'
      },
      h5: {
        fontSize: '1rem',
        '@media (min-width:600px)': {
          fontSize: '2rem',
        },  
        fontFamily: 'roboto_light',
        textAlign: 'left',
        color: '#26018C' 
      }
    }
  });

  const setDocumentTitle = () => {
     document.title = 'PintPaser'
  }


  useEffect(() => {
    setDocumentTitle()
}, [])
  

  return (
    <Box>
    <ThemeProvider theme={theme}>
    <Router>
      <div>
        <MyRouter  />
      </div>
    </Router>
    </ThemeProvider>
    </Box>
  );
};

export default App;

