import React, { FC, useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';





interface TopbarProps {
    children: any,
}

export const TopbarBadge: FC<TopbarProps> = (props) => {

    return (
            <Avatar
                sx={{ width: 55, height: 55, fontSize: '2vw', backgroundColor: 'primary.main', borderStyle: 'solid', borderColor: "primary.main", borderRadius: '50%', borderWidth: '4px'}}>
                {props.children}
            </Avatar>
    )
}



