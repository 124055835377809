import React, { FC, useState, Suspense } from "react";
import Grid from '@mui/material/Grid';
import { Container, Row, Col, Card, FormGroup, Button, Input, Label, Form } from "reactstrap";
import "./Loginpage.css"
import axios from "axios"
import Cookies from 'js-cookie'
import { useHistory } from "react-router-dom";
import Alert from '@mui/material/Alert';




export const Loginpage: FC = () => {

    var query = process.env.REACT_APP_API_URL + "/login"

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [wrongCredentials, setWrongCredentials] = useState(false)

    const history = useHistory();

    const fetchapiData = async () => {
        let message = {
            email: email,
            password: password,
        }

        return await axios({
            method: 'post',
            url: query,
            data: message
        });
    }

    const fetchalldata = async () => {
        return Promise.all([fetchapiData()]).then(([data]) => { return { data }; })
    }

    const encrypt = (data: any) => {
        var CryptoJS = require('crypto-js')
        var key = 'FbcCY2yCFBwVCUE9R+6kJ4fAL4BJxxjd';
        var iv = 'e16ce913a20dadb8';
        var encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), { iv: CryptoJS.enc.Utf8.parse(iv) });
        var encry = CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
        var enc_encryp = encodeURIComponent(encry)
        return enc_encryp

    }
    const setCookies = async (data: any) => {
        var returned_data = data.data.data
        Cookies.set('access_token', returned_data.access_token, { expires: 7 })
        Cookies.set('email', email, { expires: 7 })
    }


    const handleLogin = async () => {
        const promise = fetchalldata();
        await promise.then(async data => {
            setCookies(data).then(() => {
            })
            try {
                if (data.data.data.access_token) {
                    history.push("/")
                } else {
                    console.log('here', data.data.data)
                    setWrongCredentials(true)
                }
            } catch {
                alert("Falscher Login, probiere es nochmal!!");
            }
        });

    }

    const fetchrep = async (endpoint: string) => {
        let query = process.env.REACT_APP_API_URL + endpoint
        return Promise.all([fetchrequest(query)]).then(([data]) => { return { data }; })
    }

    const fetchrequest = async (query: string) => {
        return await axios.get(query);
    }

    return (
        <Grid container spacing={5} direction='column'>
            <Grid item sm={12}>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Row>
                                <Col sm="12"><h1 className="centeredHeader">Bier, Glas, Stoppuhr und LOS! </h1> </Col>
                            </Row>

                            <Card style={{ border: "none", boxShadow: "none" }}>
                                <Row>
                                    <Col sm="6" >
                                    {wrongCredentials &&
                                            <Alert severity='error'> Falsche E-Mail-Adresse oder falsches Passwort</Alert>
                                        }
                                        <Form>
                                            <FormGroup>
                                                <Label for="exampleEmail">Dein Benutzername</Label>
                                                <Input type="email" name="email" id="exampleEmail" placeholder="Bruderkek69" value={email} onChange={e => { setEmail(e.target.value); setWrongCredentials(false) }} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="examplePassword">Passwort</Label>
                                                <Input type="password" name="password" id="examplePassword" placeholder="Gib hier dein Passwort ein" value={password} onChange={e => { setPassword(e.target.value); setWrongCredentials(false) }} />
                                            </FormGroup>
                                            <Button style={{ color: 'black', backgroundColor: '#F1C31F' }}
                                                onClick={handleLogin}>Anmelden</Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm="12">
                            <p> </p>
                            <p> </p>
                        </Col>
                    </Row>
                </Container>
            </Grid>
        </Grid>
    )
}


