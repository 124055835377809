import React, { FC, useState, useEffect, useCallback} from "react";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Topbar } from "../../components/topbar/Topbar";
import { CourseCard } from "../../components/CourseCard";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';



export const Mainpage: FC = () => { 

    return (
        <Grid container spacing={10} justifyContent='center' alignItems='center' style={{overflow:'scroll'}} >
           <Grid item xs={12}>
                <Topbar>
                </Topbar>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h3"  sx={{  fontFamily:'am_amatic', color:'secondary.main', textAlign:'center'}}>
                    Nicht lang schnacken, Kopf in Nacken
                </Typography>
            </Grid>

            <Grid item>
                <Card sx={{  borderRadius: 2, maxHeight:600}} >
                    <CardContent component={Link} to={'/report/'}>
                    <CardMedia
                        component="img"
                        height="300"
                        image={process.env.PUBLIC_URL + '/Report.png'}
                        alt="green iguana"
                    />
                    </CardContent>
                    <CardContent component={Link} to={'/report/'}>
                        {/* <Typography gutterBottom variant="h5" component="div" style={{margin: '0 10px'}}>
                            {props.course_title}
                        </Typography> */}
                        <Typography  variant="h4" >
                            Neuer Report
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" component={Link} to={'/report/'} disabled={false}>
                            Ab gehts!
                        </Button>
                    </CardActions>
                </Card>  
            </Grid>
            <Grid item >
                <Card sx={{  borderRadius: 2, maxHeight:600}} >
                    <CardContent component={Link} to={'/dashboard/'}>
                    <CardMedia
                        component="img"
                        height="300"
                        image={process.env.PUBLIC_URL + '/dashboard.png'}
                        alt="green iguana"
                    />
                    </CardContent>
                    <CardContent component={Link} to={'/dashboard/'}>
                        {/* <Typography gutterBottom variant="h5" component="div" style={{margin: '0 10px'}}>
                            {props.course_title}
                        </Typography> */}
                        <Typography  variant="h4" >
                            Dashboard
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" component={Link} to={'/dashboard/'} disabled={true}>
                            Ab gehts!
                        </Button>
                    </CardActions>
                </Card>  
            </Grid>
            <Grid item xs={12}/>
        </Grid>
)}