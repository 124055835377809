import React, { FC, useState, useRef } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Fullscreen, Logout } from '@mui/icons-material/';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HomeIcon from '@mui/icons-material/Home'
import AppsIcon from '@mui/icons-material/Apps';
import screenfull from "screenfull";
import "./Topbar.css"
import Box from '@mui/material/Box';
import { StayPrimaryLandscape } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { InputGroup } from "reactstrap";
import { AccountMenu } from "../AccountMenu";
import Cookies from 'js-cookie'
import {TopbarBadge} from "../TopbarBadge"
import Tooltip from '@mui/material/Tooltip';


interface TBParams {
}


export const Topbar: FC<TBParams> = (props) => {
    
  const navigate = (to) => {
      window.open(to, '_self')
  }

  return (
    <Grid item >
      <AppBar position="static" sx={{ bgcolor: 'white' }}>
        <Toolbar >
          <Grid item container alignItems="center" style={{ textAlign: 'center' }}>

            <Grid item xs={1} 
                  onDoubleClick={() => navigate('/')}
                >
              <Tooltip title="Bitte doppelt klicken bzw. tippen">
              <IconButton
                  onClick={() => navigate('/')}
              >
                <TopbarBadge>
                  <AppsIcon sx={{ fontSize: 40, color: 'white' }} style={{ textAlign: 'right' }} />
                </TopbarBadge>
              </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={2}/>

            <Grid item xs={6} >
              <img style={{width:'auto', height:'80.5px'}} src={process.env.PUBLIC_URL + '/bier_logo.jpg'} />
            </Grid>


            <Grid item xs={1} sm={1}>
              <AccountMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};


