import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Mainpage } from "../mainpage";
import Cookies from 'js-cookie'
import { Loginpage } from "../login";
import { AdminRoute } from "./AdminRoute";
import axios from 'axios';
import { Report } from "../Report";



export const MyRouter: FC = () => {


  const fetchrequest = async (query: string) => {
    return await axios.get(query);
  }

  const isAuthenticated = () => {
    const token = Cookies.get('access_token')
    return token ? true : false;
  };

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );



  const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      
      var cookie = cookies[i];
      console.log('c', cookie);
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      Cookies.remove(name);
    }
  }

  const LogoutRoute: any = () => {
    deleteAllCookies();
    Cookies.remove('apitoken')
    Cookies.remove('email')
    Cookies.remove('userid')
    Cookies.remove('role')

    return (
      <Route>
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      </Route>
    )
  }

  return (
    <div>
      <Switch>
        <Route exact path="/login" component={Loginpage} />
        <LogoutRoute exact path="/logout"><Loginpage /></LogoutRoute>
        <ProtectedRoute exact path="/" component={Mainpage}/>
        <ProtectedRoute path='/report' component={Report} />
      </Switch>
    </div>
  );
}

