import React, { FC, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import SchoolIcon from '@mui/icons-material/School';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie'
import { VolumeUp, ContactSupport, ArrowForwardIos, ArrowBackIos, PinDropSharp, MoveUp, ZoomIn, ZoomOut, SmartToy, TipsAndUpdates, QuestionAnswer, Help, RateReview, Home, SignLanguage } from "@mui/icons-material";



export const AccountMenu: FC = () => {

  const [feedback, setFeedback] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAccName = () => {
    var email = Cookies.get('email')
    if (email != undefined) {
      return email
    }
    else {
      return 'Test Account'
    }
  }

  const getShortAccName = () => {
    var email = Cookies.get('email')
    if (email != undefined) {
      return email[0].toUpperCase()
    }
    else {
      return 'T'
    }
  }

  const getAvatarColor = () => {
    var email = Cookies.get('email')
    if (email != undefined) {
      return 'secondary.main'
    }
    else {
      return 'grey'
    }
  }


  //<MenuItem component={Link} onClick={() => setFeedback(!feedback)}>

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <IconButton
            onClick={handleClick}
            size="large"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{
              width: 55, height: 55, fontSize: '2vw', borderStyle: 'solid', borderColor: "primary.main", borderRadius: '50%', borderWidth: '4px',
              backgroundColor: getAvatarColor()}}> 
              {getShortAccName()}</Avatar>
          </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to={'/support/'}>
          <ListItemIcon>
            <QuestionAnswer />
          </ListItemIcon>
          Kontakt
        </MenuItem>
        <Divider />
        <MenuItem>
          <Avatar /> {getAccName()}
        </MenuItem>
        <MenuItem component={Link} to={"/settings/"}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Einstellungen
        </MenuItem>
        <MenuItem component={Link} to={"/adminoverview/"}>
          <ListItemIcon>
            <SchoolIcon fontSize="small" />
          </ListItemIcon>
          Admiralitätspodium
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to={"/logout"}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}



