import React, { FC, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Topbar } from "../../components/topbar/Topbar";
import Cookies from 'js-cookie'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpIcon from '@mui/icons-material/Help';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { styled } from '@mui/material/styles';
import { DeliveryDining } from "@mui/icons-material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from "react-router-dom";
import Alert from '@mui/material/Alert';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}


const currencies = [
  {
    value: 'Export',
    label: 'Export',
  },
  {
    value: 'Weizen',
    label: 'Weizen',
  },
  {
    value: 'Pils',
    label: 'Pils',
  },
  {
    value: 'Hefe',
    label: 'Hefe',
  },
];

const glasses = [
  {
    value: 'HalbeGlas',
    label: 'Halbe Glas',
  },
  {
    value: 'Horn',
    label: 'Horn',
  },
  {
    value: 'WeizenGlas',
    label: 'Weizen Glas',
  }
];

const positions = [
  {
    value: 'stehend',
    label: 'stehend',
  },
  {
    value: 'sitzend',
    label: 'sitzend',
  },
  {
    value: 'gekniet',
    label: 'gekniet',
  }
];

interface FormData {
  time: number;
  rating: string;
  emotion: string;
  beer_type: string;
  glas_type:string
  eg_emotion:string;
  eg_schwapp: boolean;
  eg_saug: boolean;
  mg_emotion:string;
  mg_schwapp: boolean;
  mg_saug: boolean;
  lg_emotion:string;
  lg_schwapp: boolean;
  lg_saug: boolean;
  position: string;
  breathing: string;
  impact:string;
}

export const Report: FC = (props) => {
  const [saved, setSaved] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    time: 0, rating: "2", emotion: "2", beer_type: 'Export', glas_type:'HalbeGlas',
    position: 'gekniet', eg_emotion: "2", eg_schwapp: false, eg_saug: false, 
    mg_emotion: "2",mg_schwapp: false, mg_saug: false, 
    lg_emotion: "2", lg_schwapp: false, lg_saug: false, breathing:"2", impact:"2"
  })
  const history = useHistory();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log('target', e.target)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setSaved(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('formData', formData)
    await fetchup()
    history.push("/")
  }

  const fetchup = async () => {
    let querymeasurement = process.env.REACT_APP_API_URL + "/save_user_data"
    return Promise.all([fetchrup(querymeasurement)]).then(([data]) => { return { data }; })
  }

  const fetchrup = async (query: string) => {
    var token: any = Cookies.get('access_token')
    const headers = { 'Authorization': 'Bearer ' + token }
    return await axios.post(query, { data: formData, userName: Cookies.get('email') }, { headers: headers });
  }


  return (

    <Grid container direction={'column'} spacing={1} style={{ textAlign: 'center' }}>
      <Topbar />
      <Grid item xs={12} />
      <Grid item>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
        <Grid container direction={'column'} spacing={1} style={{ textAlign: 'center' }}>
          <div>
            <Grid item style={{ textAlign: 'center' }}>
            <Typography variant="h3" component="legend">Zeitmessung</Typography>
            </Grid>
            <TextField
              required
              id="time"
              name="time"
              type='number'
              label="Zeit in s"
              inputProps={{lang:"de-DE"}}
              onChange={handleInputChange}
            />
          </div>
          </Grid>
          <div></div>
          <div></div>
          <div>
            <Typography variant="h3" component="legend">Bier-Parameter</Typography>
            <TextField
              id="outlined-select-currency"
              name='beer_type'
              select
              label="Select"
              defaultValue="Export"
              helperText="Welche Biersorte?"
              onChange={handleInputChange}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="outlined-select-currency"
              name='glas_type'
              select
              label="Select"
              defaultValue="HalbeGlas"
              helperText="Welches Gefäß?"
              onChange={handleInputChange}
            >
              {glasses.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="outlined-select-currency"
              name='position'
              select
              label="Select"
              defaultValue="gekniet"
              helperText="Welche Position?"
              onChange={handleInputChange}
            >
              {positions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            <Typography variant="h3" component="legend">Pre Game</Typography>
            <Typography component="legend">Pre-thing </Typography>
            <StyledRating
              name="breathing"
              defaultValue={2}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              onChange={handleInputChange}
              highlightSelectedOnly
            />
          </div>
          <div>
            <Typography variant="h3" component="legend">Early Game</Typography>
            <Typography component="legend">Zufrieden? </Typography>
            <StyledRating
              name="eg_emotion"
              defaultValue={2}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              onChange={handleInputChange}
              highlightSelectedOnly
            />
            <FormControlLabel control={<Checkbox />} name='eg_schwapp' label="Geschwappt?" onChange={handleInputChange}/>
            <FormControlLabel control={<Checkbox />} name='eg_saug' label="Gesaugt?" onChange={handleInputChange}/>
          </div>
          <div>
            <Typography variant="h3" component="legend">Mid Game</Typography>
            <Typography component="legend">Zufrieden? </Typography>
            <StyledRating
              name="mg_emotion"
              defaultValue={2}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              onChange={handleInputChange}
              highlightSelectedOnly
            />
            <FormControlLabel control={<Checkbox />} name='mg_schwapp' label="Geschwappt?" onChange={handleInputChange}/>
            <FormControlLabel control={<Checkbox />} name='mg_saug' label="Gesaugt?" onChange={handleInputChange}/>
          </div>
          <div>
            <Typography variant="h3" component="legend">Late Game</Typography>
            <Typography component="legend">Zufrieden? </Typography>
            <StyledRating
              name="lg_emotion"
              defaultValue={2}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              onChange={handleInputChange}
              highlightSelectedOnly
            />
            <FormControlLabel control={<Checkbox />} name='lg_schwapp' label="Geschwappt?" onChange={handleInputChange}/>
            <FormControlLabel control={<Checkbox />} name='lg_saug' label="Gesaugt?" onChange={handleInputChange}/>
          </div>
          <div>
          <Typography variant="h3" component="legend">After Game</Typography>
            <StyledRating
              name="impact"
              defaultValue={2}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              onChange={handleInputChange}
              highlightSelectedOnly
            />
          </div>
          <div>
            <Typography variant="h3" component="legend">Wie fühlst du dich generell?</Typography>
            <StyledRating
              name="emotion"
              defaultValue={2}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              onChange={handleInputChange}
              highlightSelectedOnly
            />
          </div>
          <div>
            <Button type="submit" variant='contained' sx={{ width: '18rem' }} size='large' >
              Absenden
            </Button>
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}